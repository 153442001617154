import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import NProgress from 'nprogress'
import 'antd/dist/reset.css'
import '../styles/tailwind.css'
import '../styles/styles.css'
import '../styles/nprogress.css'
// import '../styles/variables.less'
import idID from 'antd/lib/locale/id_ID';
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import { RecoilRoot } from "recoil";
import { ConfigProvider } from 'antd'

dayjs.locale('id')

function MyApp({ Component, pageProps }) {
    const router = useRouter()
    NProgress.configure({ showSpinner: false })

    useEffect(() => {
        router.events.on('routeChangeStart', () => {
            NProgress.start()
        })
        router.events.on('routeChangeComplete', () => {
            NProgress.done()
        })
        router.events.on('routeChangeError', () => {
            NProgress.done()
        })
        return () => {
            router.events.off('routeChangeStart', () => {
                NProgress.start()
            })
            router.events.off('routeChangeComplete', () => {
                NProgress.done()
            })
            router.events.off('routeChangeError', () => {
                NProgress.done()
            })
        }
    }, [])

    return (
        <>
            <Head>
                <title>Mengkopos</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>

            <RecoilRoot>
                <ConfigProvider
                    locale={{
                        ...idID,
                        DatePicker: {
                            ...idID.DatePicker,
                            lang: {
                                ...idID.DatePicker.lang,
                                ok: "Ok"
                            }
                        }
                    }}
                    theme={{
                        token: {
                            colorPrimary: '#b99540',
                            colorTextQuaternary: '#d5ad4d',
                        },
                    }}
                >
                    <Component {...pageProps} />
                </ConfigProvider>
            </RecoilRoot>
        </>
    )
}

export default MyApp
